// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action, toJS } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcIsUndefined,
  AcFormatRequestParameters,
} from '@utils';
import { makeApiCall } from '../api/api-utils';

const _default = {
  company: null,
  companies: null,
  selectedEquipments: null,
  selectedContracts: null,
  options: {},
};

let app = {};

export class ErpStore {
  constructor(store) {
    makeObservable(this);
    app.store = store;
    //AcAutoSave(this);
  }

  @observable
  companies = null;

  @observable
  company = null;

  @observable
  prevCompany = null;

  @observable
  contracts = null;

  @observable
  selectedContracts = [];

  @observable
  selectedEquipments = [];

  @observable
  selectedUnavailableEquipments = [];

  @computed
  get current_companies() {
    return this.companies || [];
  }
  @computed
  get current_company() {
    return this.company;
  }

  @computed
  get current_contracts() {
    return this.contracts || [];
  }
  @computed
  get current_equipments() {
    return (this.contracts && this.contracts.equipments) || [];
  }

  @computed
  get current_company_query() {
    return (this.companiesQuery && this.companiesQuery) || '';
  }

  @computed
  get current_selected_equipments() {
    return (this.selectedEquipments && toJS(this.selectedEquipments)) || [];
  }

  @computed
  get hasSelectedUnavailableEquipments() {
    return this.selectedUnavailableEquipments.length ? true : false;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  companiesQuery = null;

  @computed
  get currentSelectedContracts() {
    return toJS(this.selectedContracts);
  }

  // @computed
  // get current_selected_contracts() {

  // }

  // SELECTIONS:
  @action
  addSelectedContract = (contractOrderNo) => {
    this.selectedContracts = [...this.selectedContracts, contractOrderNo];
  };
  @action
  removeFromSelectedContracts = (contractOrderNo) => {
    this.selectedContracts = this.selectedContracts.filter(
      (contract) => contractOrderNo !== contract
    );
  };
  @action
  addAllSelectedContracts = () => {
    this.selectedContracts = this.current_contracts.map(
      (cc) => cc.order_number
    );
  };
  @action
  removeAllSelectedContracts = () => {
    this.selectedContracts.clear();
  };

  @action
  addSelectedEquipment = (equipmentId, is_available) => {
    this.selectedEquipments = [...this.selectedEquipments, equipmentId];
    if (!is_available)
      this.selectedUnavailableEquipments = [
        ...this.selectedUnavailableEquipments,
        equipmentId,
      ];
  };
  @action
  removeFromSelectedEquipments = (equipmentId) => {
    this.selectedEquipments = this.selectedEquipments.filter(
      (id) => id !== equipmentId
    );
    this.selectedUnavailableEquipments =
      this.selectedUnavailableEquipments.filter((id) => id !== equipmentId);
  };

  @action
  addAllSelectedEquipments = (includeUnavailable, fromSelectedContracts) => {
    const filterUnavailable = (lineObj) => {
      if (!lineObj.is_available && includeUnavailable) {
        this.selectedUnavailableEquipments = [
          ...this.selectedUnavailableEquipments,
          lineObj.object_no,
        ];
      }
      return includeUnavailable ? lineObj : lineObj.is_available;
    };

    this.selectedEquipments = fromSelectedContracts
      .map((c) => c.lines.slice())
      .flat()
      .map((lineObj) => ({ ...lineObj }))
      .filter(filterUnavailable)
      .map((line) => line.object_no);
  };
  @action
  removeAllSelectedEquipments = () => {
    this.selectedEquipments.clear();
    this.selectedUnavailableEquipments.clear();
  };

  @action
  setCompaniesQuery = (input) => {
    if (AcIsSet(input)) {
      if (this.companiesQuery !== input) this.companiesQuery = input;
    }
  };

  @action
  resetParams = () => {
    this.query = null;
  };

  @action
  resetCompanies = () => {
    this.companies = [];
  };
  @action
  resetCompany = () => {
    this.selectedContracts = [];
    this.selectedEquipments = [];
    this.company = null;
  };

  @action
  resetAll = () => {
    this.resetCompanies();
    this.resetCompany();
    this.selectedContracts = [];
    this.contracts = [];
    this.companiesQuery = '';
    this.selectedEquipments = [];
    this.selectedUnavailableEquipments = [];
  };

  @action
  resetEquipments = () => {
    this.selectedUnavailableEquipments = [];
  };

  apiCall = (apiFn, { paramFn, toaster = {}, success, error }) =>
    action(async (options) => {
      this.setLoading(true);
      let params;
      if (paramFn) {
        params = paramFn(AcFormatRequestParameters);
      } else {
        params = AcFormatRequestParameters(this, options);
      }
      const { start, end, fail } = toaster;
      const toasterInstance = (await (start && start())) || null;

      const callApi = Object.keys(params).length
        ? app.store.api.erp[apiFn]({ ...params })
        : app.store.api.erp[apiFn](options);
      return callApi
        .then((response) => {
          if (end && toasterInstance) end(toasterInstance.id, response);
          return success(response);
        })
        .catch((err) => {
          if (fail && toasterInstance && !axios.isCancel(error))
            fail(toasterInstance.id, err);
          return error(err);
        })
        .finally(() => {
          this.setLoading(false);
        });
    });

  @action
  listAllCompanies = () => {
    this.setLoading(true);

    let params = {};

    if (this.current_company_query && this.current_company_query !== '') {
      params = {
        q: this.current_company_query,
      };
    }

    return app.store.api.erp
      .listCompanies(params)
      .then(({ data }) => {
        this.set(KEYS.COMPANIES, data, true);

        this.setLoading(false);

        return data;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve companies',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  listAllContracts = this.apiCall('listContracts', {
    paramFn: (format) =>
      format(this, { customer_erp_id: this.current_company.erp_id }),
    success: ({ data }) => {
      this.set(KEYS.CONTRACTS, data, true);
      return data;
    },
    error: (error) => {
      if (!axios.isCancel(error))
        app.store.toasters.add({
          variant: 'error',
          title: 'Failed to retrieve contracts',
          description: AcFormatErrorMessage(error),
        });
      if (!axios.isCancel(error)) throw error;
    },
  });

  getContract = this.apiCall('getContract', {
    success: ({ data }) => {
      this.set(KEYS.CONTRACTS, [data], true);
      this.selectedContracts = [data.order_number];
      return data;
    },
    error: (error) => {
      if (!axios.isCancel(error))
        app.store.toasters.add({
          variant: 'error',
          title: 'Failed to retrieve contract',
          description: AcFormatErrorMessage(error),
        });
      if (!axios.isCancel(error)) throw error;
    },
  });

  store = this.apiCall('store', {
    paramFn: () => {
      const getSelectedContractsAndEquipment = () => {
        const contracts = this.current_contracts.slice().filter((cc) => {
          return this.currentSelectedContracts.find(
            (csc) => csc === cc.order_number
          );
        });
        const objectNoSelected = ({ object_no }) =>
          this.current_selected_equipments.includes(object_no);

        const equipments = contracts
          .map((contract) => {
            const lines = contract.lines.slice();
            const filteredLines = lines.filter(objectNoSelected);
            return {
              order_number: contract.order_number,
              ...(filteredLines.length && {
                lines: [
                  ...filteredLines.map((l) => ({ object_no: l.object_no })),
                ],
              }),
              ...(!filteredLines.length && {
                lines: null,
              }),
            };
          })
          .flat();
        return equipments;
      };
      return {
        customer_erp_id: this.current_company.erp_id,
        ...(this.currentSelectedContracts.length && {
          contracts: getSelectedContractsAndEquipment(),
        }),
      };
    },
    toaster: {
      start: () =>
        app.store.toasters.add({
          variant: 'pending',
          title: `Importing company <strong>${this.current_company.name}</strong>`,
          indeterminate: true,
        }),
      end: (toasterId, data) =>
        app.store.toasters.update(toasterId, {
          variant: 'success',
          description: `Company imported successfully.`,
          indeterminate: false,
        }),
      fail: (toasterId, error) =>
        app.store.toasters.update(toasterId, {
          variant: 'error',
          description: AcFormatErrorMessage(error),
          indeterminate: false,
        }),
    },
    success: ({ data }) => {
      //this.resetAll();
      return data;
    },
    error: (err) => {
      if (!axios.isCancel(err)) throw err;
    },
  });

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default ErpStore;
